import { Box, Typography, styled, useMediaQuery } from '@mui/material'
import {
  IdoStatus,
  useFetchOneIdoQuery,
  useFetchOneProjectByNameQuery,
} from '../../services/modules/project'
import TimeboxList from '../../pages/RevampedOverview/TimeboxList'
import Lottie from 'lottie-react'
import LogoAnimation from '../../animations/logo loading white.json'
import { useLocation, useParams } from 'react-router-dom'
import RenderInvestmentPhase from './RenderInvestmentPhase'
import { useEffect, useState } from 'react'
import { useAccount, useReadContract } from 'wagmi'
import ApeABI from '../../contracts/ape.json'
import { ethers } from 'ethers'
import {
  useFetchReferralCountQuery,
  useFetchSingleParticipationQuery,
  useFetchTotalParticipantsQuery,
  useFetchUserWinningChancesQuery,
} from '../../services/modules/participations'
import { useAppSelector } from '../../hooks'
import RenderParticipationPhase from './RenderParticipationPhase'
import { useFetchUserScoreQuery } from '../../services/modules/users'
import RenderProcessingPhase from './RenderProcessingPhase'
import RenderTGEPhase from './RenderTGEPhase'
import RenderCompletedPhase from './RenderCompletedPhase'
import { CHAIN_ID, CONTRACT_ADDRESS, MAIN_CHAIN_ID } from '../../constant'
import  * as amplitude from '@amplitude/analytics-browser';
import { PROJECT_TYPE_DICTIONARY } from '../../constants/application'
import RenderContainer from "./RenderContainer"
import RenderReceipt from "./RenderReceipt"

export const StyledText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Hellix',
  fontSize: '24px',
  fontWeight: 600,
  lineHeight: '31.2px',
  color: '#000',
  paddingBottom: '16px',
}))

export const StyledSubText = styled(Typography)(({ theme }) => ({
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 400,
  lineHeight: '24px',
  color: '#7E8180',
}))

const RevampedParticipationFlow = () => {
  const params = useParams()
  const { address } = useAccount()

  const { state }: any = useLocation()

  const user = useAppSelector(state => state.user)

  console.log('userState', user)

  const projectName = params.projectName || ''
  const idoNumber = Number(params.idoNumber || '0')

  const { data: projectDetails } = useFetchOneProjectByNameQuery(projectName)
  const projectId = projectDetails?._id || ''

  const {
    data: idoData,
  } = useFetchOneIdoQuery({ projectId, idoNumber })

  const [userInvested, setUserInvested] = useState(false)
  const [investedLeaf, setInvestedLeaf] = useState('')

  const {
    data: winningChances,
  } = useFetchUserWinningChancesQuery(
    {
      projectId,
      idoNumber,
      address: address || '',
    },
    {
      skip: !address,
    },
  )

  const [totalInvested, setTotalInvested] = useState(0)

  const { data: multiplier } = useFetchReferralCountQuery(idoData?._id, {
    skip: !idoData?._id,
  })

  const { data: scoreData } = useFetchUserScoreQuery(address || '', {
    pollingInterval: 1500000,
    skip: !address || !idoData,
  })

  const {
    data: foundParticipation,
    isLoading: foundParticipationLoading,
    isSuccess: isSuccessFoundParticipation,
  } = useFetchSingleParticipationQuery(
    {
      projectId,
      idoNumber,
      address: address || '',
    },
    {
      skip: !address || state?.participation,
    },
  )

  const {
    data: totalParticipants,
  } = useFetchTotalParticipantsQuery(
    {
      projectId,
      idoNumber,
    },
    {
      pollingInterval: 1500000,
      skip: !address,
    },
  )

  const [_userParticipation, setUserParticipation] = useState<any>(
    foundParticipation || state?.participation,
  )

  console.log('_userParticipation', _userParticipation)

  useEffect(() => {
    if (foundParticipation) {
      setUserParticipation(foundParticipation)
    }
  }, [isSuccessFoundParticipation])

  let contractAddress =
    !idoData?.investmentNetwork || idoData?.investmentNetwork === 'ETH'
      ? CONTRACT_ADDRESS.APE_INVESTMENT_UNIFIED[CHAIN_ID.ETH]
      : CONTRACT_ADDRESS.APE_INVESTMENT_UNIFIED[MAIN_CHAIN_ID]

  const {
    data: isRedeemedData,
    isLoading: isLoadingIsRedeemed,
    refetch: isRedeemed,
  } = useReadContract({
    address: contractAddress as `0x${string}`,
    abi: ApeABI,
    functionName: 'isRedeemed',
    args: [investedLeaf],
  })

  const isDown1000 = useMediaQuery('(max-width:1000px)')

  useEffect(() => {
    console.log('isRedeemedData', isRedeemedData)
    if (isRedeemedData) {
      setUserInvested(true)
    }
  }, [isRedeemedData])

  useEffect(() => {
    const referrer = document.referrer;
    if (projectDetails?.name) {
        amplitude.track('ProjectParticipationPageOpen', {
              timestamp: new Date().toISOString(),
              referrer,
              project_name: projectDetails.name.toLowerCase().replace(" ", "_"),
              project_type: idoData?.amountToRaise && idoData?.amountToRaise > 0 ? PROJECT_TYPE_DICTIONARY.IDO : PROJECT_TYPE_DICTIONARY.AIRDROP
        });
    }
  }, [projectDetails?.name])

  useEffect(() => {
    console.log('first effect')
    if (address && idoData) {
      getLeafNode()
    }
  }, [address, idoData])

  const getLeafNode = () => {
    try {
      let leaf = ethers.utils.defaultAbiCoder.encode(
        ['uint256', 'address', 'uint256', 'address'], // The datatypes of arguments to encode
        [
          idoData!.project.contractId,
          address,
          ethers.utils
            .parseUnits(
              (idoData!.amountToRaise / idoData!.totalWinners).toFixed(6),
              18,
            )
            .toString(),
          contractAddress,
        ],
      )
      console.log(leaf, 'leaf check')

      const leafKeccak = ethers.utils.keccak256(leaf)

      console.log(leafKeccak, 'leafKeccak')

      setInvestedLeaf(leafKeccak)
    } catch (error) {
      console.log('getLeafNode error', error)
    }
  }

  const checkIfRedeemed = async (signature: string) => {
    try {
      isRedeemed()
    } catch (error) {
      console.log('checkIfRedeemed error', error)
    }
  }

  if (!idoData || foundParticipationLoading || isLoadingIsRedeemed)
    return (
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          minHeight: '50vh',
          height: '100vh',
        }}
      >
        <Lottie
          animationData={LogoAnimation}
          loop={true}
          style={{
            height: '200px',
          }}
        />
      </Box>
    )
  // updated
  return (
    <Box
      sx={{
        position: 'relative',
        width: '100vw',
        minHeight: '100vh',
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '821.51px',
          height: '565.74px',
          left: '32%',
          top: '-45%',
          bgcolor: 'rgba(99, 234, 113, 0.4)',
          opacity: 0.5,
          filter: 'blur(250px)',
          transform: 'rotate(-51.03deg)',
          display: isDown1000 ? 'none' : 'block',
        }}
      />
      <Box
        sx={{
          position: 'absolute',
          width: '25%',
          height: '25%',
          left: '12%',
          bottom: '10%',
          bgcolor: 'rgba(99, 234, 113, 0.4)',
          opacity: 0.5,
          filter: 'blur(250px)',
          transform: 'rotate(-51.03deg)',
          display: isDown1000 ? 'none' : 'block',
        }}
      />
      <Box
        maxWidth={'1200px'}
        width={isDown1000 ? `calc(100% - 16px)` : `calc(100% - 32px)`}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
        flexDirection={'column'}
        mt={'60px'}
      >
        <TimeboxList data={idoData.project.timeline} />
        {/* <RenderInvestmentPhase
              project={projectDetails}
              userInvested={userInvested}
              _userParticipation={_userParticipation}
              user={user}
              idoData={idoData}
              totalInvested={totalInvested}
              score={scoreData}
              totalParticipants={totalParticipants}
              totalAssetsConnected={idoData.totalAssetsConnected}
              multiplierData={multiplier}
              onInvested={checkIfRedeemed}
              /> */}
        <Box
          zIndex={2}
          sx={{
            borderRadius: '16px',
            display: 'flex',
            justifyContent: 'center',
            // height: '100%',
            // minHeight: isDown1000 ? '360px' : '520px',
            maxWidth: '1200px',
            width: `100%`,
            marginBottom: '100px',
            background: isDown1000
              ? 'none'
              : 'linear-gradient(142.67deg, rgba(200, 254, 11, 0.2) 0%, rgba(255, 255, 255, 0.1) 51.04%, rgba(200, 254, 11, 0.4) 100%)',
          }}
        >
          {idoData.status === IdoStatus.PARTICIPATION && (
            <RenderParticipationPhase
              score={scoreData}
              totalParticipants={totalParticipants}
              totalAssetsConnected={idoData.totalAssetsConnected}
              winningChances={winningChances}
              idoData={idoData}
              multiplierData={multiplier}
              _userParticipation={_userParticipation}
            />
          )}
          {idoData.status === IdoStatus.PROCESSING && (
            <RenderProcessingPhase
              score={scoreData}
              totalParticipants={totalParticipants}
              totalAssetsConnected={idoData.totalAssetsConnected}
              idoData={idoData}
              multiplierData={multiplier}
            />
          )}
          {idoData.status === IdoStatus.INVESTMENT && (
            <RenderInvestmentPhase
              project={projectDetails}
              userInvested={isRedeemedData}
              _userParticipation={foundParticipation}
              user={user}
              idoData={idoData}
              totalInvested={totalInvested}
              score={scoreData}
              totalParticipants={totalParticipants}
              totalAssetsConnected={idoData.totalAssetsConnected}
              multiplierData={multiplier}
              onInvested={checkIfRedeemed}
            />
          )}

          {idoData.status === IdoStatus.CANCELLED &&
            isRedeemedData &&
            _userParticipation?.isWinner && (
              <RenderContainer
                score={scoreData ?? 0}
                totalParticipants={totalParticipants ?? 0}
                totalAssetsConnected={idoData.totalAssetsConnected}
                multiplierData={multiplier?.multiplier ?? 0}
                idoData={idoData}
                sideComponent={
                  <RenderReceipt
                    idoData={idoData}
                    score={scoreData!}
                    totalParticipants={totalParticipants}
                    totalAssetsConnected={idoData.totalAssetsConnected}
                    multiplierData={multiplier!}
                  />
                }
                type="won"
                solanaAddress={_userParticipation?.solanaAddress}
              />
          )}
          {idoData.status === IdoStatus.TGE && (
            <RenderTGEPhase
              userInvested={isRedeemedData}
              _userParticipation={foundParticipation}
              user={user}
              idoData={idoData}
              totalInvested={totalInvested}
              score={scoreData}
              totalParticipants={totalParticipants}
              totalAssetsConnected={idoData.totalAssetsConnected}
              multiplierData={multiplier}
            />
          )}

          {idoData.status === IdoStatus.COMPLETED && (
            <RenderCompletedPhase
              userInvested={userInvested}
              _userParticipation={_userParticipation}
              user={user}
              idoData={idoData}
              totalInvested={totalInvested}
              score={scoreData}
              totalParticipants={totalParticipants}
              totalAssetsConnected={idoData.totalAssetsConnected}
              multiplierData={multiplier}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default RevampedParticipationFlow
