import TemporaryDrawer from '../Drawer/drawer'
import Flex from '../Flex'
import React, { useEffect, useRef, useState } from 'react'
import { useAccount } from 'wagmi'
import { Logo, NotiActiveIcon, NotiIconDefault } from '../../assets'
import BalanceBox from '../../components/BalanceBox'
import { Box, Typography } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import ButtonLogin from '../../components/Buttons/ButtonLogin'
import LoginModal from '../../components/LoginModal'
import { shortenAddress } from '../../utils'
import DisconnectWalletModal from '../../components/DisconnectWalletModal'
import {
  INotificationHistoryResponse,
  useFetchNotificationHistoryAuthQuery,
  useFetchNotificationHistoryQuery,
} from '../../services/modules/notification'
import NotificationHeader from '../../components/NotificationHeader'

// define props
interface IMobileNavbar {
  setShowConnectWalletModal: React.Dispatch<React.SetStateAction<boolean>>
  account: string | null | undefined
}

const MobileNavbar = ({
  setShowConnectWalletModal,
  account,
}: IMobileNavbar) => {
  const { address } = useAccount()
  const navigate = useNavigate()
  const user = useAppSelector(state => state.user)
  const [openLoginModal, setOpenLoginModal] = useState(false)
  const [openSignautureModal, setOpenSignautureModal] = useState(false)
  const [showDisconnectWalletModal, setShowDisconnectWalletModal] =
    useState(false)

  const handleClick = () => {
    if (address || user.email) {
      setShowDisconnectWalletModal(true)
    } else setShowConnectWalletModal(true)
  }

  // Notification
  const isLogIn =
    (user.accessTokenGoogle && user.email) ||
    (user.mainAppToken && user.emailRegisterUserAccount) ||
    user.mainAppToken
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10
  const [showNotifications, setShowNotifications] = useState(false)
  const {
    data: dataNoti,
    isLoading: loadingData,
  } = useFetchNotificationHistoryQuery(
    {
      limit: itemsPerPage,
      page: currentPage,
    },
    {
      skip: !!isLogIn,
    },
  )

  const [allNotifications, setAllNotifications] = useState<
    INotificationHistoryResponse[]
  >([])

  useEffect(() => {
    if (!showNotifications) {
      setCurrentPage(1)
      setAllNotifications([])
    }
  }, [showNotifications])

  const {
    data: dataNotiAuth,
    refetch: refetchNotificationHistoryAuth,
    isLoading: loadingDataAuth,
  } = useFetchNotificationHistoryAuthQuery(
    {
      limit: itemsPerPage,
      page: currentPage,
    },
    {
      skip: !isLogIn,
    },
  )

  const data = isLogIn ? dataNotiAuth : dataNoti
  const hasReadNotifications = dataNotiAuth?.data.some(
    (notification: INotificationHistoryResponse) => !notification.isRead,
  )

  const handleToggleNotifications = () => {
    setShowNotifications(prevState => !prevState)
  }

  const notificationRef = useRef<HTMLElement | null>(null)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target as Node)
      ) {
        setShowNotifications(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (!address && !user.email) {
      setShowDisconnectWalletModal(false)
      navigate('/')
    }
  }, [address, navigate, user.email])

  return (
    <>
      <Flex jc="space-between" w="100%" h="100%">
        <img
          src={Logo}
          style={{
            cursor: 'pointer',
          }}
          onClick={() => {
            navigate('/')
          }}
          alt="logo"
        />

        <Box
          sx={{
            display: 'flex',

            alignItems: 'center',
          }}
        >
          {!user.email && !user.emailRegisterUserAccount && !address && (
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '16px',
              }}
            >
              <ButtonLogin onClick={() => setOpenLoginModal(true)} />
            </Box>
          )}
          {user.email && !address && (
            <Typography
              fontWeight={400}
              fontSize={16}
              fontFamily={'Inter'}
              onClick={handleClick}
              color={'rgba(118, 118, 118, 1)'}
              sx={{
                // on hover
                '&:hover': {
                  color: '#63ea71',
                },
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                marginBlockStart: 0,
                marginBlockEnd: 0,
                marginRight: '20px',
                cursor: 'pointer',
              }}
            >
              {shortenAddress(user.email!, 5, 4)}
            </Typography>
          )}
          {address && <BalanceBox />}
          <Box
            onClick={handleToggleNotifications}
            display={'flex'}
            ref={notificationRef}
            flexDirection={'column'}
            alignItems={'center'}
            marginRight={2}
            sx={{
              cursor: 'pointer',
              position: 'relative',
            }}
          >
            {isLogIn ? (
              hasReadNotifications ? (
                <img src={NotiActiveIcon} alt="NotiIconActive" />
              ) : (
                <img src={NotiIconDefault} alt="NotiIconDefault" />
              )
            ) : (
              <img src={NotiActiveIcon} alt="NotiActiveIcon" />
            )}
            {showNotifications && (
              <Box
                sx={{
                  position: 'absolute',
                  top: '64px',
                  right: '-35px',
                  zIndex: 999,
                }}
              >
                <NotificationHeader
                  loadingData={loadingData}
                  loadingDataAuth={loadingDataAuth}
                  data={data}
                  currentPage={currentPage}
                  refetchNotificationHistoryAuth={
                    refetchNotificationHistoryAuth
                  }
                  setAllNotifications={setAllNotifications}
                  allNotifications={allNotifications}
                  showNotifications={showNotifications}
                  setCurrentPage={setCurrentPage}
                  setShowNotifications={setShowNotifications}
                />
              </Box>
            )}
          </Box>
          <TemporaryDrawer />
        </Box>
      </Flex>
      <LoginModal
        open={openLoginModal}
        onClose={() => setOpenLoginModal(false)}
        openSignautureModal={openSignautureModal}
        setOpenSignautureModal={setOpenSignautureModal}
      />
      <DisconnectWalletModal
        open={showDisconnectWalletModal}
        setOpen={setShowDisconnectWalletModal}
      />
    </>
  )
}

export default MobileNavbar
