import { Tooltip } from '@mui/material';
import { Box } from '@mui/system';
import React, { Component } from 'react';
import { getReplacedCdnEndpoint } from '../../utils';

import { ReactComponent as WebIcon } from '../../assets/web.svg'
import { ReactComponent as XIcon } from '../../assets/x.svg'
import { ReactComponent as TelegramIcon } from '../../assets/telegram3.svg'
import { ReactComponent as DiscordIcon3 } from '../../assets/discord2.svg'
import { ReactComponent as RedditIcon } from '../../assets/reddit.svg'
import { ReactComponent as MediumIcon } from '../../assets/medium.svg'
import { ReactComponent as InstagramIcon } from '../../assets/instagram.svg'
import { ReactComponent as GithubIcon2 } from '../../assets/github.svg'
import YouTubeIcon from '@mui/icons-material/YouTube'

interface SocialMediaProps {
  links?: string[],
}

const SocialMedia: React.FC<SocialMediaProps> = ({links}) => {
  if (!links) return 'TBA';

  return (
    <Box
      display={'flex'}
      alignItems={'center'}
      justifyContent={'center'}
      flexDirection={'row'}
      gap={'4px'}
    >
      {links.slice(0, 2).map((link, index) => (
        <RenderIcon key={index} url={link} />
      ))}
    </Box>
  );
}

export default SocialMedia;

interface RenderIconProps {
  url: string
}

const RenderIcon: React.FC<RenderIconProps> = ({ url }) => {
  const openLinkInNewTab = () => {
    window.open(url, '_blank')
  }

  const iconProps = {
    height: '24px',
    width: '24px',
    color: '#767676',
    transition: 'all 0.3s',
  }

  const getIcon = (icon: any, imageSrc?: any) => (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        height: '32px',
        width: '32px',
        border: '1px solid #1D1F21',
        borderRadius: '60px',
        cursor: 'pointer',
        transition: 'all 0.3s',
        '& * path': {
          transition: 'all 0.3s',
        },
        ':hover': {
          backgroundColor: '#0F1113',
        },
        ':hover .white_hover_svg:not([fill="none"])': {
          fill: '#ffffff !important',
        },
        ':hover .white_hover_svg[fill="none"] path': {
          fill: '#ffffff !important',
        },
      }}
      className="white_hover_svg__wrapper"
    >
      <Tooltip title={url}>
        {imageSrc ? (
          <img
            style={{ ...iconProps, height: '24px' }}
            src={getReplacedCdnEndpoint(imageSrc)}
            alt={url}
            onClick={openLinkInNewTab}
          />
        ) : (
          React.createElement(icon, {
            className: 'white_hover_svg',
            onClick: openLinkInNewTab,
            style: iconProps,
          })
        )}
      </Tooltip>
    </Box>
  )

  if (url.includes('x.com') || url.includes('twitter')) {
    return getIcon(XIcon)
  } else if (url.includes('t.me')) {
    return getIcon(TelegramIcon)
  } else if (url.includes('youtubbe')) {
    return getIcon(YouTubeIcon)
  } else if (url.includes('insta')) {
    return getIcon(InstagramIcon)
  } else if (url.includes('reddit')) {
    return getIcon(RedditIcon)
  } else if (url.includes('discord')) {
    return getIcon(DiscordIcon3)
  } else if (url.includes('medium')) {
    return getIcon(MediumIcon)
  } else if (url.includes('github')) {
    return getIcon(GithubIcon2)
  } else {
    return getIcon(WebIcon, null)
  }
}
