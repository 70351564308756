import React, { useEffect, useRef, useState } from 'react'
import { Box, useMediaQuery } from '@mui/material'
import { Container } from './styles'
import {
  NotiIconDefault,
  SkullIcon,
  TextLogo,
  NotiActiveIcon,
} from '../../assets/index'
import navList from '../../components/NavItem/list'
import NavItem from '../../components/NavItem'
import { useLocation, useNavigate } from 'react-router-dom'
import BalanceAndAddressBox from '../../components//BalanceAndAddressBox'
import { useAppDispatch, useAppSelector } from '../../hooks/'
import { setKycStatus, setWalletAddress } from '../../redux/slices/userSlice'
import GiftBox from '../../components/GiftBox'
import useDisconnectWalletAndProfile from '../../hooks/useDisconnectWalletAndEmail'
import MobileNavbar from './MobileNavbar'
import { useAccount, useSignTypedData } from 'wagmi'
import LoginModal from '../../components/LoginModal'
import ButtonLogin from '../Buttons/ButtonLogin'
import ButtonArcade from '../Buttons/ButtonArcade'
import NotificationHeader from '../../components/NotificationHeader'
import {
  INotificationHistoryResponse,
  useFetchNotificationHistoryAuthQuery,
  useFetchNotificationHistoryQuery,
} from '../../services/modules/notification'

// import TemporaryDrawer from './drawer.tsx'
interface INavbar {
  isLoading: boolean
  giftBannerHeight: string
}
const Navbar: React.FC<INavbar> = ({ isLoading, giftBannerHeight }) => {
  const { pathname } = useLocation()
  const navigate = useNavigate()
  const { reset: resetData } = useSignTypedData()
  const [openSignautureModal, setOpenSignautureModal] = useState(false)
  const [openLoginModal, setOpenLoginModal] = useState(false)

  const { address, chain } = useAccount()

  const user = useAppSelector(state => state.user)
  const { disconnectWalletAndProfile } = useDisconnectWalletAndProfile()
  const [showConnectWalletModal, setShowConnectWalletModal] = useState(false)

  const dispatch = useAppDispatch()
  const handleProfileIcon = () => {
    if (user.isLoggedIn) disconnectWalletAndProfile()
    else navigate('/portfolio')
  }

  // Notification
  const isLogIn =
    (user.accessTokenGoogle && user.email) ||
    (user.mainAppToken && user.emailRegisterUserAccount) ||
    user.mainAppToken
  const [currentPage, setCurrentPage] = useState(1)
  const itemsPerPage = 10
  const [showNotifications, setShowNotifications] = useState(false)
  const {
    data: dataNoti,
    isLoading: loadingData,
  } = useFetchNotificationHistoryQuery(
    {
      limit: itemsPerPage,
      page: currentPage,
    },
    {
      skip: !!isLogIn,
    },
  )

  const {
    data: dataNotiAuth,
    refetch: refetchNotificationHistoryAuth,
    isLoading: loadingDataAuth,
  } = useFetchNotificationHistoryAuthQuery(
    {
      limit: itemsPerPage,
      page: currentPage,
    },
    {
      skip: !isLogIn,
    },
  )

  const data = isLogIn ? dataNotiAuth : dataNoti
  const hasReadNotifications = dataNotiAuth?.data.some(
    (notification: INotificationHistoryResponse) => !notification.isRead,
  )
  const [allNotifications, setAllNotifications] = useState<
    INotificationHistoryResponse[]
  >([])

  useEffect(() => {
    if (!showNotifications) {
      setCurrentPage(1)
      setAllNotifications([])
    }
  }, [showNotifications])

  const handleToggleNotifications = () => {
    setShowNotifications(prevState => !prevState)
  }

  const notificationRef = useRef<HTMLElement | null>(null)
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target as Node)
      ) {
        setShowNotifications(false)
      }
    }

    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [])

  const isDown1050 = useMediaQuery('(max-width:1050px)')

  const isDown1200 = useMediaQuery('(max-width:1200px)')
  const elligibleForGiftCard = address

  useEffect(() => {
    // setTheme({
    //   themeVariables: {
    //     '--w3m-z-index': '1000',
    //   },
    // })
    // setThemeVariables({
    //   '--w3m-z-index': 1000,
    // })
  }, [])

  // useEffect(() => {
  //   if (address && user.walletAddres === '') dispatch(setWalletAddress(address))
  //   else {
  //     if (address !== user.walletAddres) {
  //       disconnectWalletAndProfile()
  //       navigate('/')
  //       dispatch(
  //         setSnackbar({
  //           open: true,
  //           message: 'Connect your wallet again',
  //           severity: 'error',
  //         }),
  //       )
  //     }
  //   }
  // }, [address])

  useEffect(() => {
    if (!address) {
      setOpenSignautureModal(false)
      disconnectWalletAndProfile()
      dispatch(setWalletAddress(''))
      // removeDataGoogleFromRedux()
      resetData()
    }

    if (address && user.walletAddress === '') {
      dispatch(setWalletAddress(address))

      // signTypedData()
      setOpenSignautureModal(true)
    }

    if (
      address &&
      user.walletAddress !== '' &&
      address !== user.walletAddress
    ) {
      console.log('setWalletAddress cond2', address)
      dispatch(setWalletAddress(address))
      dispatch(setKycStatus('rejected'))

      // signTypedData()
      setOpenSignautureModal(true)
    }
  }, [address, chain, user.walletAddress])

  // useEffect(() => {
  //   if (address && !mainAppToken) {
  //     //sign message from user once securing address
  //     // don't sign if you are on localhost
  //     // if (window.location.hostname !== 'localhost')
  //     //signTypedData()
  //   }
  // }, [address, mainAppToken])

  // watchAccount(account => {
  //   console.log('account changed', account)
  //   if (account.status === 'connected') signTypedData()
  // })

  const [prevScrollPos, setPrevScrollPos] = useState(0)
  const [isSticky, setSticky] = useState(false)

  const handleScroll = () => {
    const currentScrollPos = window.scrollY
    setSticky(currentScrollPos > 0)
    setPrevScrollPos(currentScrollPos)
  }

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [prevScrollPos])

  return (
    <Container
      container
      padding={isDown1200 ? '12px 15px' : '10px 24px 10px 24px'}
      sx={{
        position: isSticky ? 'fixed' : 'absolute',
        top: !isSticky ? giftBannerHeight : 0,
        width: '100%',
        height: isSticky ? '70px' : 'auto',
        backdropFilter: isSticky ? 'blur(8px)' : 'none',
        backgroundColor: isSticky ? 'rgba(255,255,255,0.72)' : 'transparent',
        zIndex: isSticky ? 1001 : 1000,
        transition:
          'background-color 0.3s ease-in-out, opacity 0.3s ease-in-out, height 0.3s ease-in-out',
        marginBottom: '100px',
      }}
    >
      {isDown1200 ? (
        <MobileNavbar
          setShowConnectWalletModal={setShowConnectWalletModal}
          account={address}
        />
      ) : (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Box
            display={'flex'}
            flexDirection={'column'}
            alignItems={'start'}
            sx={{
              cursor: 'pointer',
              float: 'left',
              flex: address || elligibleForGiftCard ? 1 : 0,
            }}
            onClick={() => navigate('/')}
          >
            <img src={TextLogo} alt="logo" width="130" height="40" />
          </Box>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center', 
              alignItems: 'center',    
              flexGrow: 1,              
              gap: '2px',              
              padding: '5px 3px',
              maxHeight: '40px',
              height: '40px',
            }}
          >
            {navList.map(nav => {
              if (!address && nav.name === 'Portfolio') return null
              else
                return (
                  <NavItem
                    key={nav.name}
                    text={nav.name}
                    href={nav.href}
                    target={nav.target}
                    submenu={nav.submenu}
                    textStyles={{
                      fontSize: 15,
                      fontWeight: '400',
                      fontFamily: 'Inter',
                      border: 'none',
                      backgroundColor:
                        pathname === nav.href
                          ? 'rgba(111, 235, 124, 0.03)'
                          : 'transparent',
                      color: pathname === nav.href ? '#63EA71' : '#fff',
                    }}
                    active={pathname === nav.href}
                  />
                )
            })}
          </Box>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              flexWrap: 'wrap-reverse',
              gap: '20px',
            }}
          >
            <Box
              sx={{
                maxHeight: '40px',
                gap: '8px',
                display:
                  address || elligibleForGiftCard || user.email
                    ? 'flex'
                    : 'none',
                float: 'right',
                flex: address && elligibleForGiftCard && user.email ? 1 : '',
                justifyContent: 'right',
              }}
            >
              {elligibleForGiftCard && <GiftBox mobile={false} />}
              {(address || user.email || user.emailRegisterUserAccount) && (
                <BalanceAndAddressBox />
              )}

              {(address || user.email || user.emailRegisterUserAccount) && (
                <Box
                  sx={{
                    backgroundColor: 'rgba(15, 17, 19, 1)',
                    height: '40px',
                    width: '40px',
                    borderRadius: '50%',
                    textAlign: 'center',
                  }}
                >
                  <img
                    src={user.isLoggedIn ? user.profilePicture : SkullIcon}
                    style={{
                      height: '32px',
                      width: '32px',
                      borderRadius: user.isLoggedIn ? '15px' : '0px',
                      cursor: 'pointer',
                      marginTop: '4px',
                    }}
                    alt="profile-icon"
                    onClick={handleProfileIcon}
                  />
                </Box>
              )}
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '16px',
              }}
            >
              {!user.email && !user.emailRegisterUserAccount && !address && (
                <ButtonLogin onClick={() => setOpenLoginModal(true)} />
              )}
              <Box
                onClick={handleToggleNotifications}
                ref={notificationRef}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
                sx={{
                  cursor: 'pointer',
                  position: 'relative',
                }}
              >
                {isLogIn ? (
                  hasReadNotifications ? (
                    <img src={NotiActiveIcon} alt="NotiIconActive" />
                  ) : (
                    <img src={NotiIconDefault} alt="NotiIconDefault" />
                  )
                ) : (
                  <img src={NotiActiveIcon} alt="NotiActiveIcon" />
                )}
                {showNotifications && (
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '64px',
                      left: '50%',
                      transform: 'translateX(-50%)',
                      zIndex: 999,
                    }}
                  >
                    <NotificationHeader
                      loadingData={loadingData}
                      loadingDataAuth={loadingDataAuth}
                      data={data}
                      currentPage={currentPage}
                      refetchNotificationHistoryAuth={
                        refetchNotificationHistoryAuth
                      }
                      setAllNotifications={setAllNotifications}
                      allNotifications={allNotifications}
                      setCurrentPage={setCurrentPage}
                      showNotifications={showNotifications}
                      setShowNotifications={setShowNotifications}
                    />
                  </Box>
                )}
              </Box>

              <ButtonArcade />
            </Box>
          </Box>
        </Box>
      )}

      <LoginModal
        open={openLoginModal}
        onClose={() => setOpenLoginModal(false)}
        openSignautureModal={openSignautureModal}
        setOpenSignautureModal={setOpenSignautureModal}
      />
    </Container>
  )
}
export default Navbar
