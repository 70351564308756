import React, { useEffect, useState } from 'react'
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import { useNavigate, useSearchParams } from 'react-router-dom'

import { FilledBlackArrow, GreenTick, TimerDots } from '../../assets'
import {
  MakeParticipationPayload,
  useLazyFetchSingleParticipationQuery,
  useParticipateInProjectMutation,
} from '../../services/modules/participations'
import {
  capitalizeFirstLetter,
  replaceUnderscoresWithSpaces,
} from '../../utils'
import { PROJECT_TYPE_DICTIONARY } from '../../constants/application'
import useSignMessageAndRegisterAccount from '../../hooks/useSignMessageAndRegisterAccount'
import Countdown from 'react-countdown'
import { useAccount, useBalance } from 'wagmi'
import { useAppSelector } from '../../hooks'
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { IdoStatus, useFetchOneIdoQuery } from '../../services/modules/project'
import TimeBox from './TimeBox'
import { useAnalytics } from '../../AmplitudeContext'
import { useCode } from '../../hooks/useReferralCode'
import { useParticipationEntryPoint } from '../../Context'
import { IS_GCC_WEBSITE } from '../../constant'
import { useDispatch } from "react-redux"
import { setWalletAddress } from "../../redux/slices/userSlice"

interface IParticipationBox {
  id: string
  name: string
  showTickets?: boolean
  ido: any
  time?: number
}
const ParticipationBox: React.FC<IParticipationBox> = ({
  id,
  name,
  showTickets,
  ido,
  time,
}) => {
  const { mainAppToken, primaryReferralCodes } =
    useAppSelector(state => state.user)

  const { address } = useAccount()
  const { open } = useWeb3Modal()
  const dispatch = useDispatch()

  const [foundParticipation, setfoundParticipation] = useState(false)

  const [searchParam] = useSearchParams()

  const referCode = searchParam.get('code')

  const [navigationLoading, setNavigationLoading] = useState(false)
  const theme = useTheme()
  const isDown600 = useMediaQuery(theme.breakpoints.down(600))

  const [fetchSingleParticipation, { isLoading: isLoadingIfParticipated }] =
    useLazyFetchSingleParticipationQuery()

  const findParticipation = async (address: string) => {
    const response = await fetchSingleParticipation({
      projectId: id,
      idoNumber: 0,
      address: address,
    })

    if (response.data) {
      return setfoundParticipation(true)
    }
    return setfoundParticipation(false)
  }

  const [
    participate,
    {
      data: participationData,
      isLoading: isLoadingParticipation,
      isSuccess: isSuccessParticipation,
    },
  ] = useParticipateInProjectMutation()
  useEffect(() => {
    if (address && mainAppToken) findParticipation(address)
  }, [address, mainAppToken, id])

  useEffect(() => {
    if (isSuccessParticipation && participationData) {
      navigateToParticipatePage()
    }
  }, [isSuccessParticipation])

  const { loader, callSignTypedData } = useSignMessageAndRegisterAccount()

  const navigate = useNavigate()

  const navigateToParticipatePage = () => {
    console.log('participationData', participationData)

    navigate(`/participate/${name}/${0}`, {
      state: {
        participation: participationData,
      },
    })
  }
  const {
    data: idoData,
  } = useFetchOneIdoQuery(
    {
      projectId: id,

      idoNumber: 0,
    },
    {
      skip: !id,
    },
  )


  const { trackEvent, updateProjectJoin, updateWalletSize } = useAnalytics();
  const code = useCode();
  const { data: ethBalanceData } = useBalance({
    address,
  })
  const participateHandler = async (idoNumber: number, projectId: string) => {
    try {
      console.log(
        '🚀 ~ file: ParticipationBox.tsx:132 ~ participateHandler ~ referCode',
        { referCode, primaryReferralCodes, projectName: name },
      )

      const payload: MakeParticipationPayload = {
        idoNumber,
        projectId,
      }
      if (referCode) {
        payload.code = referCode
      }
      const balance = ethBalanceData ? ethBalanceData.formatted : '0';
      const response = await participate(payload)
      console.log(response, 'check response from rtq')
      trackEvent('ProjectJoin', {
        wallet_size: Number.parseFloat(balance) ? Number.parseFloat(balance).toString() : 0,
        project_name: name?.toLowerCase().replace(" ", "_"),
        referral_code: code,
        project_type: idoData?.amountToRaise && idoData?.amountToRaise > 0 ? PROJECT_TYPE_DICTIONARY.IDO : PROJECT_TYPE_DICTIONARY.AIRDROP
      })
      updateProjectJoin();
      updateWalletSize(balance);
    } catch (error) {
      console.error('could not participate because:', error)
    }
  }

  const { setParticipationEntryPoint } = useParticipationEntryPoint()
  const handleButtonClick = async () => {
    if (!address) {
      dispatch(setWalletAddress(''))
      await open()
    }
    setParticipationEntryPoint('ProjectPage')
    if (address && !mainAppToken) {
      callSignTypedData()
    } else if (foundParticipation) {
      navigateToParticipatePage()
    } else {
      await participateHandler(0, id)
    }
  }

  // const now = new Date().getTime()
  // const isShowButtonJoinNow = 
  // idoData?.startDate && 
  // idoData?.endDate &&
  // now >= new Date(idoData.startDate).getTime() &&
  // now <= new Date(idoData.endDate).getTime() &&
  // ido?.status === 'Participation' &&
  // !foundParticipation;


  const RenderText = () => {
    if (!address) return 'Please Connect Wallet'

    if (address && !mainAppToken) return 'Provide Signature'
    if (ido?.status !== 'Participation' && !foundParticipation)
      return 'Registration Ended'
    else if (foundParticipation) return 'View Participation'
    else return 'Join Now'
  }

  const showLoader =
    navigationLoading ||
    loader ||
    isLoadingIfParticipated ||
    isLoadingParticipation

  const buttonDisabled =
    !address || (ido?.status !== 'Participation' && !foundParticipation)

  const idoProcessText =
    ido?.status === IdoStatus.PARTICIPATION
      ? ' registration ends in'
      : ido?.status === IdoStatus.PROCESSING
      ? ' investment starts in'
      : ido?.status === IdoStatus.INVESTMENT
      ? ' investment ends in'
      : ' registration ends in'

  // if (!isShowButtonJoinNow) return null

  return (
    <Grid
      container
      padding={isDown600 ? '8px' : '24px'}
      bgcolor={'#fff'}
      borderRadius={'12px'}
      flexDirection={'column'}
      border={'1px solid rgba(0, 0, 0, 0.2)'}
      maxHeight={'100%'}
      gap={'16px'}
    >
      {ido?.status !== 'Completed' && (
        <Typography
          fontSize={'16px'}
          fontWeight={400}
          lineHeight={'24px'}
          color={'#000'}
          fontFamily={'Inter'}
        >
          {capitalizeFirstLetter(replaceUnderscoresWithSpaces(name))}
          {showTickets ? ' sale closes in' : idoProcessText}:
        </Typography>
      )}

      {time &&
        (time === -1 ? (
          <Typography
            fontWeight={600}
            fontSize={20}
            lineHeight={'32px'}
            color={'#ff7722'}
          >
            TBD
          </Typography>
        ) : (
          <Countdown
            date={time}
            renderer={({ days, hours, minutes, seconds, completed }) => {
              let _timerString = ''
              if (days > 0) {
                _timerString = `${days}d ${hours}h ${minutes}m ${seconds}s`
              } else if (hours > 0) {
                _timerString = `${hours}h ${minutes}m ${seconds}s`
              } else {
                _timerString = `${minutes}m ${seconds}s`
              }

              return (
                <>
                  {completed ? (
                    <Box display={'flex'} alignItems={'center'} gap={2}>
                      <img src={GreenTick} alt="green tick" />
                      <Box>
                        <Typography
                          fontWeight={500}
                          fontSize={17}
                          lineHeight={'27px'}
                          color={'#000'}
                        >
                          Registration Ended
                        </Typography>
                        <Typography
                          fontSize={'16px'}
                          fontWeight={400}
                          lineHeight={'24px'}
                          color={'#767676'}
                          fontFamily={'Inter'}
                        >
                          {capitalizeFirstLetter(
                            replaceUnderscoresWithSpaces(name),
                          )}{' '}
                          registration ended.
                        </Typography>
                      </Box>
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        display: 'flex',
                        gap: isDown600 ? '2px' : '8px',
                        alignItems: 'center',
                        maxHeight: '69px',
                      }}
                    >
                      <TimeBox type="Days" value={days} />
                      <img
                        src={TimerDots}
                        alt="timer dots"
                        height={'fit-content'}
                      />
                      <TimeBox type="Hours" value={hours} />

                      <img
                        src={TimerDots}
                        alt="timer dots"
                        height={'fit-content'}
                      />
                      <TimeBox type="Minutes" value={minutes} />

                      <img
                        src={TimerDots}
                        alt="timer dots"
                        height={'fit-content'}
                      />
                      <TimeBox type="Seconds" value={seconds} />
                    </Box>
                  )}
                </>
              )
            }}
          />
        ))}

      {showTickets && (
        <>
          <Box
            display={'flex'}
            flexDirection={'row'}
            justifyContent={'space-between'}
            alignItems={'center'}
            mt={'12px'}
          >
            <Typography
              fontSize={'16px'}
              fontWeight={400}
              lineHeight={'24px'}
              color={'#B1B4B3'}
              fontFamily={'Inter'}
            >
              Sold Tickets
            </Typography>

            <Typography
              fontSize={'20px'}
              fontWeight={400}
              lineHeight={'32px'}
              color={'#767676'}
              fontFamily={'Inter'}
            >
              <span
                style={{
                  color: '#F4FDAB',
                  fontSize: '20px',
                  fontWeight: 600,
                  fontFamily: 'Hellix',
                }}
              >
                25
              </span>
              /100
            </Typography>
          </Box>
          <LinearProgress
            sx={{
              background: '#1D1F21',
              height: '16px',
              borderRadius: '20px',
              '& .MuiLinearProgress-bar': {
                background:
                  'linear-gradient(48.3deg, #FF7722 38.22%, #FFB152 74.28%)',
                boxShadow: 'inset -0.5px 0.5px 0px rgba(255, 255, 255, 0.7)',
                height: '16px',
                borderRadius: '20px',
                transform: 'translateX(0%) !important',
                width: '25%', // change this to change the progress
              },
            }}
            variant="determinate"
            value={25}
          />
        </>
      )}

      {showLoader ? (
        <Box sx={{ width: '100%' }}>
          <LinearProgress sx={{ bgcolor: '#63EA71' }} />
        </Box>
      ) : (
        <Button
          variant="greenContainer"
          startIcon={<img src={FilledBlackArrow} alt="filled black arrow" />}
          sx={{
            mt: '12px',
            borderRadius: '24px',
            height: '48px',
            display: RenderText() === 'Registration Ended' ? 'none' : 'flex',
            '&:hover': {
              opacity: 0.8,
            },
          }}
          disabled={IS_GCC_WEBSITE}
          onClick={handleButtonClick}
        >
          <Typography
            fontWeight={400}
            fontSize={'15px'}
            lineHeight={'22.5px'}
            color="#000"
            fontFamily={'Inter'}
          >
            {RenderText()}
          </Typography>
        </Button>
      )}
    </Grid>
  )
}
export default ParticipationBox
