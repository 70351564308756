import React, { useEffect, useState } from 'react'
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
  useMediaQuery,
  styled,
  tableRowClasses,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { sixDigitsFormatter } from '../../utils'

export const StyledHeadTableCell = styled(TableCell)({
  backgroundColor: '#f2f2f2',
  borderBottom: '1px solid rgba(0,0,0,0.2)',
  color: '#767676',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  height: '48px',
  flexShrink: 1,
  padding: '0px 10px',
})
export const StyledBodyTableCell = styled(TableCell)({
  height: '56px',
  borderBottom: '1px solid rgba(0,0,0,0.2)',
  color: '#767676',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '15px',
  flexShrink: 1,
  whiteSpace: 'nowrap',
  padding: '0px 10px',
  maxHeight: '56px',
  lineHeight: '15px',
})
export const StyledTotalCell = styled(TableCell)({
  height: '56px',
  backgroundColor: '#f2f2f2',
  borderBottom: '1px solid rgba(0,0,0,0.2)',
  color: '#000',
  fontFamily: 'Inter',
  fontStyle: 'normal',
  fontWeight: 700,
  fontSize: '15px',
  flexShrink: 1,
  whiteSpace: 'nowrap',
  padding: '0px 10px',
  maxHeight: '56px',
  lineHeight: '15px',
})

const TokenTable: React.FC<{ tokenMetrics: any }> = ({ tokenMetrics }) => {
  const [totals, setTotals] = useState({
    allocation: 0,
    allocationPercentage: 0,
    amount: 0,
    price: 0,
    raise: 0,
  })

  useEffect(() => {
    // Calculate totals
    const newTotals = tokenMetrics.reduce(
      (acc: any, metric: any) => {
        return {
          allocation: acc.allocation + (metric.allocation || 0),
          allocationPercentage:
            acc.allocationPercentage + (metric.allocationPercentage || 0),
          amount: acc.amount + (metric.amount || 0),
          price: acc.price + (metric.price || 0),
          raise: acc.raise + (metric.raise || 0),
        }
      },
      { allocation: 0, allocationPercentage: 0, amount: 0, price: 0, raise: 0 },
    )
    setTotals(newTotals)
  }, [tokenMetrics])

  const isDown1152 = useMediaQuery('(max-width:1152px)')

  return (
    <Box width={'100%'}>
      <Box
        width={'100%'}
        maxWidth={'1200px'}
        display={'flex'}
        flexDirection={'column'}
        paddingLeft={'0px'}
        paddingTop={'0px'}
      >
        <TableContainer
          component={Paper}
          sx={{
            height: '100%',
            maxHeight: 'max-content',
            borderRadius: isDown1152 ? '12px' : '16px',
            border: '1px solid rgba(0,0,0,0.2)',
            background: 'unset',
            boxShadow: 'none',
            backgroundImage: 'none',
            flexShrink: 1,
            width: '100%',
            '&::-webkit-scrollbar': {
              display: 'flex',
              height: '4px',
            },
            '&::-webkit-scrollbar-thumb': {
              backgroundColor: '#313335',
            },
            '&::-webkit-scrollbar-track': {
              backgroundColor: '#0F1113',
            },
          }}
        >
          <Table
            stickyHeader
            aria-label="sticky table"
            sx={{
              height: '100%',
              flexShrink: 0,
            }}
          >
            <TableHead>
              <TableRow
                sx={{
                  textWrap: 'nowrap',
                }}
              >
                <StyledHeadTableCell>Allocation</StyledHeadTableCell>
                <StyledHeadTableCell>Allocation %</StyledHeadTableCell>
                <StyledHeadTableCell>Amount</StyledHeadTableCell>
                <StyledHeadTableCell>Price</StyledHeadTableCell>
                <StyledHeadTableCell>Raise</StyledHeadTableCell>
                <StyledHeadTableCell>TGE</StyledHeadTableCell>
                <StyledHeadTableCell>Vesting</StyledHeadTableCell>
              </TableRow>
            </TableHead>
            <TableBody
              style={{
                overflowY: 'hidden',
                overflowX: 'auto',
                backgroundColor: '#fff',
              }}
            >
              {tokenMetrics.map((metric: any) => (
                <TableRow
                  key={metric.id}
                  sx={{
                    '&:hover': {
                      backgroundColor: '#FF7722',
                      'td': {
                        color: '#000',
                      }
                    },
                    [`&.${tableRowClasses.root}`]: {
                      height: '56px',
                      maxHeight: '56px',
                    },
                  }}
                >
                  <StyledBodyTableCell size={'small'} padding="none">
                    {metric.allocation}
                  </StyledBodyTableCell>
                  <StyledBodyTableCell size={'small'}>
                    {metric.allocationPercentage}
                  </StyledBodyTableCell>
                  <StyledBodyTableCell size={'small'}>
                    {sixDigitsFormatter(metric.amount)}
                  </StyledBodyTableCell>
                  <StyledBodyTableCell size={'small'}>
                    {metric.price === -1
                      ? '-'
                      : `$${sixDigitsFormatter(metric.price)}`}
                  </StyledBodyTableCell>
                  <StyledBodyTableCell size={'small'}>
                    {metric.raise === -1
                      ? '-'
                      : `$${sixDigitsFormatter(metric.raise)}`}
                  </StyledBodyTableCell>
                  <StyledBodyTableCell size={'small'}>
                    {metric.TGE}
                  </StyledBodyTableCell>
                  <StyledBodyTableCell
                    size={'small'}
                    sx={{
                      paddingRight: isDown1152 ? '40px' : '20px',
                      maxWidth: isDown1152 ? '100%' : '300px',
                      whiteSpace: isDown1152 ? 'nowrap' : 'wrap',
                      color: '#767676',
                      fontSize: '13px',
                      lineHeight: '120%',
                    }}
                  >
                    {metric.vesting}
                  </StyledBodyTableCell>
                </TableRow>
              ))}
              <TableRow>
                <StyledTotalCell>Total</StyledTotalCell>
                <StyledTotalCell>
                  {sixDigitsFormatter(totals.allocationPercentage)}
                </StyledTotalCell>
                <StyledTotalCell>
                  {sixDigitsFormatter(totals.amount)}
                </StyledTotalCell>
                <StyledTotalCell></StyledTotalCell>
                <StyledTotalCell>
                  ${sixDigitsFormatter(totals.raise)}
                </StyledTotalCell>
                <StyledTotalCell></StyledTotalCell>
                <StyledTotalCell></StyledTotalCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  )
}

export default TokenTable
