import { Box, Typography, useMediaQuery, useTheme } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import riveWASMResource from '@rive-app/canvas/rive.wasm'
import Rive, {
  Layout,
  Fit,
  Alignment,
  RuntimeLoader,
} from '@rive-app/react-canvas'
import { Helmet } from 'react-helmet'
import _ from 'lodash'
import Motion from '../../components/Motion/Motion'
import {
  NewHomepageBg,
  NewHomepageBgMobile,
  HeroBackgroundDecor,
  HeroBackgroundDecorMobile,
  ReferencesBg,
} from '../../assets'
import { ReactComponent as TelegramNew } from '../../assets/telegram_new.svg'
import { ReactComponent as TwitterNew } from '../../assets/twitter_new.svg'

import UpcomingLaunch from './UpcomingLaunch'
import {
  LaunchType,
  useFetchAllLaunchesQuery,
  useFetchAllProjectsQuery,
  useFetchLaunchpadPublicStatsQuery,
} from '../../services/modules/project'
import { useLocation, useNavigate } from 'react-router-dom'
// import Lottie from 'lottie-react'
// import LogoAnimation from '../../animations/logo loading white.json'
import { getReplacedCdnEndpoint, scrollToTop } from '../../utils'
// import OurStatistics from './OurStatistics'
import ParticipationSection from './ParticipationSection'
import HeroBanner from './HeroBanner'
import CompletedSales from './CompletedSales'
import LinkButtons from './LinkButtons'
import AnimatedStatistics from './AnimatedStatistics'
import { height, positions } from '@mui/system'
import { AnalyticsProvider, useAnalytics } from '../../AmplitudeContext'
import { useWalletInfo } from '@web3modal/wagmi/react'
import { useCode } from '../../hooks/useReferralCode'
import { truncateExact } from '../../utils'
import { useAccount, useBalance } from 'wagmi'
import { useAppSelector } from '../../hooks'
import { sendPushNotification } from '../../services/notification'
import Stats from './Stats'
import Funds from './Funds'
import References from './References'
import UnparalleledFeatures from './UnparalleledFeatures'
import TwitterPosts from './TwitterPosts'
import FAQ from '../FAQ/FAQ'
import ApeAcademy from './ApeAcademy'
import SubscribeEmail from './SubscribeEmail'
import {
  useFetchUserSubscribeQuery,
} from '../../services/modules/users'

const RevampedLaunchpad: React.FC = () => {
  RuntimeLoader.setWasmUrl(riveWASMResource)

  const { data } = useFetchAllProjectsQuery()
  const { address } = useAccount()
  const navigate = useNavigate()

  const location = useLocation()

  const theme = useTheme()

  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown600 = useMediaQuery('(max-width:600px)')
  const isDown360 = useMediaQuery('(max-width:360px)')
  const isDown1220 = useMediaQuery('(max-width:1220px)')
  const isDown1366 = useMediaQuery('(max-width:1366px)')
  const isDown1650 = useMediaQuery('(max-width:1650px)')
  const isDown1000 = useMediaQuery('(max-width:1000px)')
  const isDown850 = useMediaQuery('(max-width:850px)')

  const STATE_MACHINE_NAME = 'Interaction'

  const Links = [
    { icon: TelegramNew, link: 'https://t.me/ApeTerminalChat' },
    { icon: TwitterNew, link: 'https://twitter.com/apeterminal' },
  ]

  const {
    data: allLaunchesData,
    isLoading: allLaunchesLoading,
    isError: allLaunchesError,
  } = useFetchAllLaunchesQuery()
  const {
    data: launchpadStats,
    isLoading: launchpadStatsLoading,
    isError: launchpadStatsError,
  } = useFetchLaunchpadPublicStatsQuery()

  const user = useAppSelector(state => state.user)

  const { data: dataUserSubcribe } = useFetchUserSubscribeQuery(undefined, {
    skip: !user.googleToken && !user.mainAppToken,
  })

  useEffect(() => {
    scrollToTop()
  }, [location.pathname])

  useEffect(() => {
    if (!allLaunchesLoading) document?.body?.classList?.remove('no-scroll')
  }, [allLaunchesLoading])

  useEffect(() => {
    try {
      if (!_.isArray(allLaunchesData)) return
      const featuredLaunch = allLaunchesData.find(e => e.type == 'featured')
      if (!featuredLaunch) return
      const { projectTitle, logoUrl } = featuredLaunch
      const title = `${
        projectTitle || 'Unknown project'
      } registration is closing soon`
      // sendPushNotification({
      //   title,
      //   icon: getReplacedCdnEndpoint(logoUrl),
      //   id: `featured-launch-notice`,
      //   link: "/project/" + featuredLaunch.routeName,
      // })
      // const setAppNotification: (params: any) => void = _.get(
      //   window,
      //   'setAppNotification',
      // ) as unknown as (params: any) => void
      // if (typeof setAppNotification == 'function') {
      //   setAppNotification({
      //     title: 'IDO update notice',
      //     message: title,
      //     timeoutSecs: 5,
      //     link: "/project/" + featuredLaunch.routeName,
      //   })
      // }
    } catch (e) {
      console.error(e)
    }
  }, [allLaunchesData])
  return (
    <>
      <Helmet>
        <link
          rel="preload"
          href={riveWASMResource}
          as="fetch"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="rive/grid_header_ape.riv"
          as="fetch"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="rive/ape_element_01-new.riv"
          as="fetch"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="rive/ape_element_02-new.riv"
          as="fetch"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          href="rive/ape_element_03-new.riv"
          as="fetch"
          crossOrigin="anonymous"
        />
        <link
          rel="preload"
          as="image"
          href={isDown600 ? NewHomepageBgMobile : NewHomepageBg}
        />
        <link
          rel="preload"
          as="image"
          href={isDown600 ? HeroBackgroundDecorMobile : HeroBackgroundDecor}
        />
      </Helmet>

      <Box
        bgcolor={'#fff'}
        display={'flex'}
        flexDirection={'column'}
        width={'100vw'}
      >
        {/* {allLaunchesLoading && (
        <Box
          sx={{
            backgroundColor: '#000',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1000000,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Lottie
            animationData={LogoAnimation}
            loop={true}
            style={{
              height: '20vh',
            }}
          />
        </Box>
      )} */}
        <Box
          sx={{
            position: 'relative',
            overflow: isDown767 ? '' : 'hidden',
          }}
        >
          {/* <div className="rive-lines">
            <Motion
              threshold={0.1}
              duration={0.8}
              delay={0.2}
              style={{
                display: 'block',
                height: '100%',
                width: '100%',
              }}
            >
              <div
                className="rive-lines__inner"
                style={{
                  position: 'absolute',
                  top: 0,
                  left: 0,
                  width: '100%',
                  pointerEvents: 'none',
                  userSelect: 'none',
                  zIndex: 1,
                  opacity: 0.6,
                  aspectRatio: isDown600 ? '16/30' : '16/10',
                }}
              >
                <Rive
                  src="rive/grid_header_ape.riv"
                  stateMachines={STATE_MACHINE_NAME}
                  layout={
                    new Layout({
                      fit: isDown600 ? Fit.Cover : Fit.Contain,
                      alignment: Alignment.TopCenter,
                    })
                  }
                />
              </div>
            </Motion>
          </div> */}

          <Box
            sx={{
              width: '100%',
              zIndex: 1,
              fontSize: 0,
              position: isDown767 ? 'absolute' : 'unset',
              height: '100%',
            }}
          >
            <img
              src={isDown767 ? NewHomepageBgMobile : NewHomepageBg}
              style={{
                width: '100%',
                height: '100%',
                pointerEvents: 'none',
                userSelect: 'none',
                aspectRatio: isDown767 ? '9/16' : '1278/749',
                objectFit: isDown767 ? 'cover' : 'unset',
                objectPosition: isDown767 ? 'top' : 'unset',
              }}
              alt="background"
            />

            {/* <Motion
              threshold={0.1}
              duration={0.8}
              style={{
                display: 'block',
                height: '100%',
                width: '100%',
                position: 'absolute',
                top: 0,
                left: 0,
              }}
            >
              <div
                className="hero-background-decor"
                style={{
                  width: '100%',
                  position: 'absolute',
                  left: '0',
                  top: isDown600 ? '27%' : '26%',
                  pointerEvents: 'none',
                  zIndex: 0,
                  userSelect: 'none',
                }}
              >
                <img
                  src={
                    isDown600 ? HeroBackgroundDecorMobile : HeroBackgroundDecor
                  }
                  style={{
                    width: '100vw',
                    height: '100%',
                    pointerEvents: 'none',
                  }}
                  alt="background-decor"
                  // className="animate-blinking"
                />
              </div>
            </Motion> */}
          </Box>
          <Box
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%',
              zIndex: 1,
              position: isDown767 ? 'relative' : 'absolute',
              top: isDown767 ? '15%' : '24%',
            }}
          >
            <Box
              display={'flex'}
              flexDirection={'column'}
              alignItems={'center'}
              justifyContent={'center'}
              px={isDown360 ? '20px' : '36px'}
              // mb={'112px'}
            >
              <Typography
                fontSize={isMobile ? 42 : 88}
                fontWeight={600}
                lineHeight={'100%'}
                color="#000"
                letterSpacing={'-0.02em'}
                textAlign={'center'}
                fontFamily={'Hellix'}
                sx={{
                  alignSelf: 'center',
                }}
              >
                <Motion
                  from={{ opacity: 1, filter: 'blur(5px)' }}
                  to={{ filter: 'blur(0px)' }}
                  threshold={0.1}
                  duration={0.5}
                  delay={0.05}
                >
                  <span
                    style={
                      {
                        // background:
                        //   'radial-gradient(77.99% 64.58% at 49.93% 17.71%, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%)',
                        // backgroundClip: 'text',
                        // WebkitBackgroundClip: 'text',
                        // WebkitTextFillColor: 'transparent',
                      }
                    }
                  >
                    Buy Before the Market
                  </span>
                </Motion>

                {/* <br />

                <Motion
                  from={{ opacity: 1, filter: 'blur(5px)' }}
                  to={{ filter: 'blur(0px)' }}
                  threshold={0.1}
                  delay={0.15}
                  duration={0.5}
                >
                  <span
                    style={{
                      background:
                        'radial-gradient(77.99% 64.58% at 49.93% 17.71%, #FFFFFF 0%, rgba(255, 255, 255, 0.6) 100%)',
                      backgroundClip: 'text',
                      WebkitBackgroundClip: 'text',
                      WebkitTextFillColor: 'transparent',
                    }}
                  >
                    Primary Market
                  </span>
                </Motion> */}
              </Typography>

              <Motion
                from={{ opacity: 0, y: 5, filter: 'blur(5px)' }}
                to={{ filter: 'blur(0px)' }}
                threshold={0.1}
                delay={0.15}
              >
                <Typography
                  fontSize={isMobile ? '17px' : '20px'}
                  fontWeight={400}
                  lineHeight={'27px'}
                  color="#4b4b4b"
                  letterSpacing={'0px'}
                  my={4}
                  textAlign={'center'}
                  fontFamily={'Inter'}
                  // px={isDown360 ? '0%' : isDown767 ? '5%' : '0%'}
                >
                  {isDown767 ? (
                    'Claim first access to the most important tokens in crypto.'
                  ) : (
                    <>
                      Ape Terminal has offered opportunities to buy in presales
                      alongside investors like <br /> Samsung NEXT, Binance
                      Labs, Arthur Hayes, and many more.
                    </>
                  )}
                </Typography>
              </Motion>

              <Stats />

              {/* <Motion
                from={{ opacity: 0, y: 5, filter: 'blur(5px)' }}
                to={{ filter: 'blur(0px)' }}
                threshold={0.1}
                delay={0.2}
              >
                <LinkButtons
                  linksArray={Links}
                  width={'140px'}
                  iconWidth={'64px'}
                />
              </Motion> */}
            </Box>
          </Box>
        </Box>
        {/* ============ Branding Funds ============ */}
        <Box
          maxWidth={'1920px'}
          width={'100%'}
          alignSelf={'center'}
          display={'flex'}
          flexDirection={'column'}
          position={'relative'}
          sx={{
            marginTop: isDown767
              ? '30%'
              : isDown850
              ? '10%'
              : isDown1000
              ? '6%'
              : isDown1366
              ? '-3%'
              : isDown1650
              ? '-8%'
              : '-12%',
          }}
        >
          <Funds />
        </Box>
        {/* =============== References =============== */}
        {/* TODO: will open it again */}
        {/* <Box
          maxWidth={'1920px'}
          width={isDown1220 ? '90%' : '100%'}
          alignSelf={'center'}
          display={'flex'}
          flexDirection={'column'}
          position={'relative'}
        >
          <References />
        </Box> */}
        {/* =========== Live and Upcoming Sales ========= */}
        <Box
          maxWidth={'1920px'}
          width={'100%'}
          alignSelf={'center'}
          display={'flex'}
          flexDirection={'column'}
          position={'relative'}
          sx={{
            marginTop: isDown600
              ? '12%'
              : isDown850
              ? '8%'
              : isDown1000
              ? '8%'
              : // : '-12%',
                '100px',
            // isDown360 ? '-40%' : isDown767 ? '-45%' : '-12%',
          }}
        >
          <UpcomingLaunch allLaunchesData={allLaunchesData || []} />
        </Box>
        <Box
          maxWidth={'1920px'}
          width={isDown1220 ? '90%' : '100%'}
          alignSelf={'center'}
          display={'flex'}
          flexDirection={'column'}
          position={'relative'}
          sx={{
            marginTop: isDown600
              ? '12%'
              : isDown850
              ? '8%'
              : isDown1000
              ? '8%'
              : // : '-12%',
                '100px',
            // isDown360 ? '-40%' : isDown767 ? '-45%' : '-12%',
          }}
        >
          {/* <AnimatedStatistics launchpadStats={launchpadStats} /> */}
          {/* <OurStatistics launchpadStats={launchpadStats} /> */}
          <UnparalleledFeatures />
          <ParticipationSection />
          {/* <TwitterPosts /> */} {/* TODO: will open it again */}
          <CompletedSales
            tokenSales={
              allLaunchesData?.filter(
                (launch: any) => launch.type === 'completed',
              ) || []
            }
          />
          <Box
            // maxWidth={'1920px'}
            // width={isDown1220 ? '90%' : '100%'}
            // alignSelf={'center'}
            // display={'flex'}
            // flexDirection={'column'}
            // position={'relative'}
            width={'100%'}
            sx={{
              // background: 'red',
              padding: isDown767 ? '0' : isDown1220 ? '0 64px' : '0 168px',
              backgroundImage: `url(${ReferencesBg})`,
              backgroundSize: 'cover',
              backgroundPositionY: '78%',
              backgroundRepeat: 'no-repeat',
            }}
          >
            <FAQ isBreakTitle={false} />
          </Box>
          {/* <ApeAcademy /> */} {/* TODO: will open it again */}
          <Box
            sx={{
              width: '100%',
              padding: isDown767 ? '0' : isDown1220 ? '0 64px' : '0 168px',
            }}
          >
            <HeroBanner />
          </Box>
          {!dataUserSubcribe?.isSubscribed && <SubscribeEmail />}
        </Box>
      </Box>
    </>
  )
}

export default RevampedLaunchpad
