import { ILaunch } from '../../services/modules/project'
import NewUpcomingLaunchCard from '../../components/NewUpcomingLaunchCard'
import { Box, Typography, useMediaQuery } from '@mui/material'
import { useEffect, useState } from 'react'
import OutlinedButton from '../../components/Buttons/OutlinedButton'
import Carousel from 'react-multi-carousel'
import 'react-multi-carousel/lib/styles.css'
import FillerCard from '../../components/FillerCard'
import './index.css'
import { getReplacedCdnEndpoint } from '../../utils/index'
import { DEFAULTS } from '../../constant'
import { LaunchesBg } from '../../assets'

interface UpcomingLaunchesProps {
  upcomingLaunchesData: ILaunch[]
}

const UpcomingLaunches = ({ upcomingLaunchesData }: UpcomingLaunchesProps) => {
  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown1050 = useMediaQuery('(max-width:1050px)')

  const launchesPerPage = 3

  const [visibleLaunches, setVisibleLaunches] = useState(launchesPerPage)

  const isiPhone = /iPhone/i.test(navigator.userAgent)

  useEffect(() => {
    if (launchesPerPage > upcomingLaunchesData.length) {
      setVisibleLaunches(upcomingLaunchesData.length)
    }
  }, [upcomingLaunchesData.length])

  const totalFillers =
    launchesPerPage - (upcomingLaunchesData.length % launchesPerPage)

  const handleShowMore = () => {
    if (visibleLaunches < upcomingLaunchesData.length) {
      setVisibleLaunches(prevVisibleLaunches =>
        prevVisibleLaunches + launchesPerPage > upcomingLaunchesData.length
          ? upcomingLaunchesData.length
          : prevVisibleLaunches + launchesPerPage,
      )
    } else {
      setVisibleLaunches(launchesPerPage)
    }
  }

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 767 },
      items: 2,
    },
    largeMobile: {
      breakpoint: { max: 766, min: 640 },
      items: 1.5,
      partialVisibilityGutter: 40,
    },
    mediumMobile: {
      breakpoint: { max: 639, min: 500 },
      items: 1.2,
      partialVisibilityGutter: 30,
    },
    smallmobile: {
      breakpoint: { max: 499, min: 450 },
      items: 1.05,
      partialVisibilityGutter: 10,
    },
    extraSmallMobile: {
      breakpoint: { max: 449, min: 300 },
      items: 1,
      partialVisibilityGutter: 10,
    },
  }

  return (
    <Box
      width="100%"
      mt={isDown767 ? 0 : '64px'}
      mb={'20px'}
      maxWidth={'1200px'}
      position={'relative'}
      overflow={'hidden'}
    >
      <Box
        position={'absolute'}
        width={'100%'}
        overflow={'hidden'}
        height={'calc(100% + 200px)'}
      >
        <img src={LaunchesBg} alt="" width={'100%'} />
      </Box>
      <Typography
        position={'relative'}
        fontWeight={600}
        fontSize={isDown767 ? 32 : 56}
        lineHeight={isDown767 ? '32px' : '61.5px'}
        letterSpacing={'0px'}
        textAlign={'center'}
        fontFamily={'Hellix'}
        color="#FFFFFF"
        mb={4}
        mt={2}
      >
        Live and Upcoming Sales
      </Typography>
      <Typography
        position={'relative'}
        fontWeight={400}
        fontSize={isDown767 ? 16 : 20}
        lineHeight={isDown767 ? '18px' : '32px'}
        letterSpacing={'0px'}
        textAlign={'center'}
        fontFamily={'Inter'}
        color="#FFFFFF"
        mb={6}
        mt={2}
      >
        Buy before the tokens list on exchanges, just like crypto’s biggest funds.
      </Typography>
      {isDown1050 && (
        <Box width={`calc(${window.innerWidth}px - 40px)`}>
          <Carousel
            responsive={responsive}
            containerClass="carousel-container"
            itemClass="custom-carousel-item"
            ssr={true}
            arrows={isiPhone ? true : false}
            showDots={false}
            rewindWithAnimation={true}
            partialVisible={true}
            rewind={true}
            infinite={true}
            swipeable={isiPhone ? false : true}
          >
            {upcomingLaunchesData
              .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
              .slice(0, upcomingLaunchesData.length)
              .map((launch: ILaunch) => (
                <NewUpcomingLaunchCard
                  key={launch._id}
                  image={getReplacedCdnEndpoint(launch.bannerImageUrl || DEFAULTS.PROJECT_BANNER_URL)}
                  heading={launch.projectTitle}
                  titleImage={getReplacedCdnEndpoint(launch.logoUrl)}
                  chips={launch.projectTags}
                  totalRaise={launch.totalRaise}
                  startsIn={launch.startsIn}
                  registrationStarted={launch.registrationStarted}
                  registrationEndDate={launch.registrationEndDate}
                  routeName={launch.routeName}
                  saleType={launch.saleType}
                  marketMaker={launch.marketMaker}
                  active={launch.active}
                  videoUrl={getReplacedCdnEndpoint(launch.videoUrl)}
                  type={launch.type}
                  started={true}
                  backers={launch.backers}
                />
              ))}
            {visibleLaunches % launchesPerPage !== 0 &&
              Array.from({ length: totalFillers }).map((_, index) => (
                <>
                  <FillerCard height="100%" />
                </>
              ))}
          </Carousel>
        </Box>
      )}
      {!isDown1050 && (
        <Box
          display="flex"
          justifyContent="center"
          flexWrap="wrap"
          gap={2}
          mb={2}
        >
          {upcomingLaunchesData
            .sort((a, b) => (a.order ?? 0) - (b.order ?? 0))
            .slice(0, visibleLaunches)
            .map((launch: any) => (
              <NewUpcomingLaunchCard
                key={launch._id}
                image={getReplacedCdnEndpoint(
                  launch.bannerImageUrl || DEFAULTS.PROJECT_BANNER_URL,
                )}
                heading={launch.projectTitle}
                titleImage={getReplacedCdnEndpoint(launch.logoUrl)}
                chips={launch.projectTags}
                totalRaise={launch.totalRaise}
                startsIn={launch.startsIn}
                registrationStarted={launch.registrationStarted}
                registrationEndDate={launch.registrationEndDate}
                routeName={launch.routeName}
                saleType={launch.saleType}
                marketMaker={launch.marketMaker}
                active={launch.active}
                videoUrl={getReplacedCdnEndpoint(launch.videoUrl)}
                type={launch.type}
                started={true}
                backers={launch.backers}
                description={launch.description}
                socialLinks={launch.socialLinks}
              />
            ))}
          {visibleLaunches % launchesPerPage !== 0 && (
            <>
              {Array.from({ length: totalFillers }).map((_, index) => (
                <>
                  <FillerCard height="540px" />
                </>
              ))}
            </>
          )}
          {upcomingLaunchesData.length > 3 && (
            <OutlinedButton
              onClick={handleShowMore}
              sx={{
                height: '48px',
                lineHeight: '16px',
                position: 'relative',
                background: '#FFFFFF',
                color: '#000000',
                transition: 'all 0.3s ease',
                '&:hover': {
                  background: '#F0F0F0',
                  color: '#000000',
                },
              }}
            >
              {visibleLaunches < upcomingLaunchesData.length
                ? 'Show More'
                : 'Show Less'}
            </OutlinedButton>
          )}
        </Box>
      )}
    </Box>
  )
}

export default UpcomingLaunches
