import React from 'react'
import { Box, Typography, useMediaQuery } from '@mui/material'
import getIconByNetwork from './IconByNetwork'
import { height, width } from '@mui/system'
import { getReplacedCdnEndpoint } from '../../utils'

const InfoBox: React.FC<{
  imageSrc: string
  headingText: string
  headingVal?: string
  tokenSymbol?: string
  spanText?: string
  hasGradient?: boolean
  multipleValues?: Array<{
    headingText: any
    headingVal: any
    tokenSymbol?: string
  }>
}> = ({
  imageSrc,
  headingText,
  headingVal,
  tokenSymbol,
  spanText,
  hasGradient = false,
  multipleValues,
}) => {
  const isDown1200 = useMediaQuery('(max-width:1200px)')
  const IconComponent =
    tokenSymbol && headingVal ? getIconByNetwork(headingVal) : null
  const isDown768 = useMediaQuery('(max-width:768px)')

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        backgroundColor: '#FF7722',
        borderRadius: '12px',
        padding: '16px',
        border: '0.5px solid rgba(255, 255, 255, 0.16)',
        width: '100%',
        marginBottom: '8px',
        overflowWrap: 'anywhere',
      }}
    >
      <Box style={{ width: '24px', height: '24px' }}>
        <img
          src={getReplacedCdnEndpoint(imageSrc)}
          alt="Image"
          style={{ width: '100%', maxHeight: '200px' }}
        />
      </Box>
      <Box
        sx={{
          mt: '24px',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <Typography
          sx={{
            fontFamily: 'Inter',
            fontWeight: 400,
            fontSize: isDown1200 ? '14px' : '15px',
            color: '#000',
          }}
        >
          {headingText}
        </Typography>

        {multipleValues && multipleValues.length > 0 && (
          <Box
            sx={{
              marginTop: '8px',
            }}
          >
            {multipleValues.map((value, index) => (
              <Box
                key={index}
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  gap: '2px',
                }}
              >
                <Typography
                  sx={{
                    fontFamily: 'Hellix',
                    fontWeight: 400,
                    fontSize: '14px',
                    color: '#000',
                    display: 'inline-flex',
                    flexFlow: 'wrap',
                    alignSelf: 'flex-start',
                    alignItems: 'baseline',
                  }}
                >
                  {value.headingText && (
                    <span
                      style={{
                        fontSize: '14px',
                        fontWeight: 600,
                        color: '#000',
                        fontFamily: 'Inter',
                        display: 'contents',
                        whiteSpace: 'pre-wrap',
                      }}
                    >
                      {value.headingText}
                    </span>
                  )}
                  {value.tokenSymbol && IconComponent && !isDown768 && (
                    <IconComponent
                      height={'32px'}
                      width={'32px'}
                      style={{
                        marginRight: '8px',
                        position: 'relative',
                        display: 'flex',
                        top: '6px',
                      }}
                    />
                  )}

                  <span
                    style={{
                      fontWeight: 400,
                      color: '#000',
                      fontFamily: 'Inter',
                      fontSize: isDown1200 ? '14px' : '14px',
                    }}
                  >
                    {value.headingVal}
                  </span>

                  {value.tokenSymbol && (
                    <span
                      style={{
                        fontSize: isDown1200 ? '16px' : '17px',
                        fontWeight: 400,
                        color: '#000',
                        fontFamily: 'Inter',
                        marginLeft: '4px',
                      }}
                    >
                      {value.tokenSymbol}
                    </span>
                  )}
                </Typography>
              </Box>
            ))}
          </Box>
        )}
        {!hasGradient && (
          <Typography
            sx={{
              fontFamily: 'Hellix',
              fontWeight: 600,
              fontSize: isDown1200 ? '20px' : '26px',
              color: '#000',
              display: 'inline-flex',
              flexFlow: 'wrap',
              alignSelf: 'flex-start',
              alignItems: 'baseline',
            }}
          >
            {tokenSymbol && IconComponent && !isDown768 && (
              <IconComponent
                height={'32px'}
                width={'32px'}
                style={{
                  marginRight: '8px',
                  position: 'relative',
                  display: 'flex',
                  top: '6px',
                }}
              />
            )}
            {headingVal}
            {tokenSymbol && (
              <span
                style={{
                  fontSize: isDown1200 ? '16px' : '17px',
                  fontWeight: 400,
                  color: '#000',
                  fontFamily: 'Inter',
                  marginLeft: '4px',
                }}
              >
                {tokenSymbol}
              </span>
            )}
            {spanText && (
              <span
                style={{
                  fontSize: isDown1200 ? '16px' : '18px',
                  fontWeight: 400,
                  color: '#000',
                  fontFamily: 'Inter',
                  display: 'contents',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {' ' + spanText}
              </span>
            )}
          </Typography>
        )}
        {hasGradient && (
          <Typography
            sx={{
              fontFamily: 'Hellix',
              fontWeight: 600,
              fontSize: isDown1200 ? '20px' : '26px',
              color: '#000',
              display: 'inline-flex',
              flexFlow: 'wrap',
              alignSelf: 'flex-start',
              alignItems: 'baseline',
              background:
                'linear-gradient(90.22deg, #FF7722 0.16%, #F4FDAB 59.47%)',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
            }}
          >
            {headingVal}
          </Typography>
        )}
      </Box>
    </Box>
  )
}

export default InfoBox
