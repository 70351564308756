import { Box, useMediaQuery } from '@mui/material'
import FeaturedLaunch from './FeaturedLaunch'
import UpcomingLaunches from './UpcomingLaunches'
import { ILaunch } from 'services/modules/project'

interface UpcomingLaunchProps {
  allLaunchesData: ILaunch[]
}

const UpcomingLaunch = ({ allLaunchesData }: UpcomingLaunchProps) => {
  const isDown1200 = useMediaQuery('(max-width:1200px)')
  const isDown767 = useMediaQuery('(max-width:767px)')
  const isDown360 = useMediaQuery('(max-width:360px)')

  return (
    <Box
      style={{
        background: '#000',
        width: '100%',
        height: isDown767 ? 'max-content' : '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        padding: isDown1200 ? '0 24px' : 0,
      }}
    >
      {/* TODO: confirm with customer to remove "featured project" */}
      {allLaunchesData && allLaunchesData.length > 0 && (
        <FeaturedLaunch
          featuredLaunchData={
            allLaunchesData?.filter(launch => launch.type == 'featured')[0]
          }
        />
      )}
      {allLaunchesData && allLaunchesData.length > 0 && (
        <UpcomingLaunches
          upcomingLaunchesData={allLaunchesData?.filter(
            launch => (launch.type === 'upcoming' || launch.type === 'coming-soon'),
          )}
        />
      )}
    </Box>
  )
}

export default UpcomingLaunch
